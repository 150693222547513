import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
var parse = require('json-parse-safe')

import "./plugins/axios";
import "./plugins/vuetify-dialog";
import "./plugins/codes";
import "./plugins/auth-image"
import "./plugins/moment"
import "./plugins/highcharts"
import "./plugins/currency"
import "./plugins/currency-field"
import "./plugins/types"
import "./plugins/mask"
import "./plugins/functions"
import "./plugins/tooltip"
import "./plugins/permissions"
import { Subject } from 'rxjs';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-3MGDEB2D5S"}
}, router);


new Vue({
  router,
  vuetify,
  render: h => h(App),
  data: {
    title: '-', 
    user: {},
    actions: {
      suggest: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      download: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      publish:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      history:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      import:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      upload:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      address:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      sync:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      productivity:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      create:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      remove:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      edit:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      editnew:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      save: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      group: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      add: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      filter: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      store: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      file: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
    },
  },
  methods: {
    show: function(action: any){
      this.actions.suggest.visible = !!action.suggest;
      this.actions.download.visible = !!action.download;
      this.actions.publish.visible = !!action.publish;
      this.actions.history.visible = !!action.history;
      this.actions.import.visible = !!action.import;
      this.actions.upload.visible = !!action.upload;
      this.actions.address.visible = !!action.address;
      this.actions.sync.visible = !!action.sync;
      this.actions.productivity.visible = !!action.productivity;
      this.actions.create.visible = !!action.create;
      this.actions.remove.visible = !!action.remove;
      this.actions.editnew.visible = !!action.editnew;
      this.actions.edit.visible = !!action.edit;
      this.actions.save.visible = !!action.save;
      this.actions.group.visible = !!action.group;
      this.actions.add.visible = !!action.add;
      this.actions.filter.visible = !!action.filter;
      this.actions.store.visible = !!action.store;
      this.actions.file.visible = !!action.file;

      //reset all the buttons that are visible
      for(var key in this.actions){
        if (this.actions[key].visible) {
          this.actions[key].disable = false;
          this.actions[key].loading = false;
          this.actions[key].disable = false;
          this.actions[key].event = new Subject();
        }

      }
    }    
  },
  created: function(){
    this.user = parse(localStorage.getItem('user')).value;
  }
}).$mount("#app");
