<template>
  <v-expansion-panels flat class="mb-6" v-model="panel">
    <v-expansion-panel @click.prevent="toggle()">
      <v-form ref="form" @submit.prevent="onFormSubmit">

        <v-expansion-panel-header expand-icon="">
          <v-row style="padding: 0px 0px 0px 0px">
            <v-col class="flex-grow-1 flex-shrink-1">
                <v-text-field
                  v-model="filters.form.data.search"
                  label="Pesquisar"
                  persistent-hint
                  outlined
                  dense                                                
                  @click.stop
              ></v-text-field>
            </v-col>
          </v-row>
         
          <!-- <template v-slot:actions>
            <v-btn icon @click.stop="toggle()">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template> -->
        </v-expansion-panel-header>        
                
        <v-chip-group v-if="panel === undefined" active-class="primary--text" style="margin-top: -25px">        
            <v-chip 
            style="background: linear-gradient(#0686FB, #9800CA)!important; 
            color: white;" @click:close="remove(tag)"
            close 
            v-for="tag in filters.tags.data" 
            :key="tag.key" 
            small>
            <strong>{{ tag.label }}:</strong>&nbsp;{{ tag.value }}
            </v-chip>        
        </v-chip-group>

      </v-form>
    </v-expansion-panel>
  </v-expansion-panels>
  
</template>

<script>
import axios from "axios";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import moment from 'moment';

const omitEmpty = require("omit-empty");

export default {
  name: "search-filter",
  props: {
    filter: {search: null}
  },
  filters: {
    moment: function (date, format) {
        if (!date) return;
        return moment(date).format(format);
    }
  },
  data: () => ({
    panel: undefined,    
    filters: {
        tags: {
            data: []
        },
               
        form: {
            valid: false,
            action: {
                search: { name: 'search', label: 'pesquisa', empty: null }
            },
            data: {
                search: null
            }    
        }
    },
  }),
  computed: {
    url: function () {
      return axios.defaults.baseURL;
    },
  },
  watch: {
    'panel': function (value) {
        var form = this.$refs.form;        
        var v = form != null;
        //this.description = this.createDescription();        
    },
    'filters.form.data': {
      deep: true,
      handler: _.debounce(function(obj) {
        this.update(obj);
        this.notify()
      }, 200),
    },   
  },
  
  mounted: function () {      
        this.filters.form.data = Object.assign(this.filters.form.data, this.$props.filter);            
        //notify
        this.update(this.filters.form.data);
        this.notify();
  },
  methods: {
    format(value, format){        
        return moment(value).format(format);
    },
    update: function(obj){
      
      //iterate over the object to check
      for(var i in obj){
        const key = obj[i];
        const oaction = this.filters.form.action[i]; 
        
        //if action was found
        if(oaction){

          //find the tag
          const tag = this.filters.tags.data.filter(e => e.key == oaction.name)[0];

          //value can be an item from an array or a primitive object
          let value = null;

          if (oaction.data){
            const data = oaction.data.split('.').reduce((o,i) => o[i], this.filters); //get the collection
            const object = data.filter(e => e[oaction.key] == key)[0]; //get the object in collection
            value = object && object[oaction.value]; //get the value of the object
          } else {
            value = key;
          }


          //get the value
          if (!value) 
          {
              if (tag) this.filters.tags.data.splice(this.filters.tags.data.indexOf(tag), 1);
          }
          else 
          {            
            if (!tag) this.filters.tags.data.push({key: oaction.name, label: oaction.label, value: value, empty: oaction.empty});
            else tag.value = value;
          }
        }
      }
    },
    reload2: _.debounce(function (value, action) { this.reload(value, action, false) }, 500),     
    reload: function (value, action, supress) {        
        var oaction = this.filters.form.action[action];
        var tag = this.filters.tags.data.filter(e => e.key == oaction.name)[0];

        if (!value) 
        {
            this.filters.tags.data.splice(this.filters.tags.data.indexOf(tag), 1);
        }
        else 
        {
          if (oaction.selector) value = value[oaction.selector];

          if (!tag) this.filters.tags.data.push({key: oaction.name, label: oaction.label, value: value, empty: oaction.empty});
          else tag.value = value;
        }

        if(!supress) this.notify();
    }, 
    remove(tag){        
        eval(`this.filters.form.data.${tag.key} = ${JSON.stringify(tag.empty)}`); //clear the filter
        this.filters.tags.data.splice(this.filters.tags.data.indexOf(tag), 1);
        this.notify();
    },
    notify() {
        var filter = this.filters.form.data;
        this.$emit("requestChange", uuid());
        this.$emit("reload", filter);
    },        
    toggle() {
      //this will toggle only by icon click. at the same time, will prevent toggle by clicking on header.
      const curr = this.panel;
      this.panel = curr === undefined ? 0 : undefined;
    },
    onFormSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>

<style>

  .mdi-close-circle::before {
      color: white !important;
  }

  .v-expansion-panel-header {
    padding: 0 !important;
  }
</style>