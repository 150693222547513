<template>
    <v-container class="login-background" >
          <v-row justify="center"  class="align-self-center" >
            <v-col cols="12" class="logo-container">
              <img :class="logoClass" src="@/assets/logo-secundaria.svg">
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="">
          <v-col cols="auto">
            <v-card style="max-width: 600px; min-width: 300px; border-radius: 12px;" outlined>
              <v-card-text class="pt-0">
                <v-tabs fixed-tabs color="white" flat v-model="tab" v-show="false">
                  <v-tab></v-tab>
                  <v-tab></v-tab> 
                  <v-tab>Recuperar senha</v-tab>   
                  <v-tab v-if="showTokenTab">Inserir Token</v-tab>                              
                </v-tabs>        
                <v-tabs-items v-model="tab">
                  <v-tab-item eager> 
                    <v-row align="center" justify="center" style="margin-top:0px" >
                      <v-form ref="form.login" v-model="form.login.valid" lazy-validation style="padding:20px;" @keyup.native.enter="form.login.valid && login($event)" >
                        <v-row dense > 
                          <v-col>
                            <p class="headline2 text-left primary-color">Login:</p>
                          </v-col>                
                        </v-row>
                        <v-row dense> 
                          <v-col>
                            <p class="body2 text-left neutral-color">Insira suas credenciais abaixo para entrar na plataforma:</p>
                          </v-col>                
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field
                              class="user-field" 
                              hide-details 
                              ref="username" 
                              outlined 
                              v-model="form.login.data.username" 
                              :rules="form.login.rules.username" 
                              label="Usuário" 
                              required 
                              :append-icon="'mdi-account'" 
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field 
                                class="password-field" 
                                hide-details 
                                ref="password" 
                                outlined 
                                v-model="form.login.data.password" 
                                :rules="form.login.rules.password" 
                                label="Senha" 
                                required 
                                :disabled="loading" 
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                                @click:append="show = !show" 
                                :type="show ? 'text' : 'password'">
                              </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-btn  
                            block 
                            text 
                            large 
                            class="primary-button"
                            :disabled="!form.login.valid || form.login.loading" 
                            :loading="form.login.loading" 
                            @click="login()">Login</v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-btn 
                            block 
                            text 
                            large 
                            class="sec-button"
                            :loading="form.login.loading" 
                            @click="tab=1">Criar nova conta</v-btn>
                          </v-col>               
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" class="text-center">
                            <v-btn text @click="tab = 2" style="color: #1976D2; text-decoration: underline; font-size: 1em; ">Esqueceu a senha?</v-btn> 
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item eager>
                    <v-toolbar  elevation="0">
                        <v-btn icon @click="tab=0">
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <span class="text-body-1 neutral-color-4">Voltar</span>
                    </v-toolbar>  
                    <v-divider></v-divider>
                      <v-form ref="form.register" v-model="form.register.valid" lazy-validation style="padding:20px;">
                        <v-row dense>
                          <v-col>
                            <p class="headline2 text-left primary-color">Criar nova conta:</p>
                          </v-col>                
                        </v-row>
                        <v-row dense>
                          <v-col>
                            <p class="text-left text-body-2 ">Preencha os dados abaixo para criar uma nova conta.</p>
                          </v-col>                
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field 
                              hide-details 
                              ref="name" 
                              outlined 
                              dense
                              v-model="form.register.data.name" 
                              :rules="form.register.rules.name" 
                              label="Nome" 
                              required 
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>                                                    
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field 
                                hide-details 
                                ref="email" 
                                outlined 
                                dense
                                v-model="form.register.data.email" 
                                :rules="form.register.rules.email" 
                                label="E-mail" 
                                required 
                                :disabled="loading" >
                              </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field 
                                ref="phone" 
                                outlined 
                                dense
                                v-model="form.register.data.phone" 
                                :rules="form.register.rules.phone" 
                                label="Telefone" 
                                required 
                                return-masked-value
                                v-mask="'(##) # ####-####'"
                                :disabled="loading" 
                                hide-details="auto" >
                              </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-autocomplete
                                v-model="form.register.data.preference"
                                :items="form.register.types.data"
                                :item-value="'value'"
                                :item-text="'text'"
                                hide-details
                                clearable
                                outlined
                                dense
                                :return-object="false"
                                label="Contato preferencial"
                                no-data-text="Sem dados :("
                                :rules="form.register.rules.preference"
                                required >
                              ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field 
                              hide-details 
                              ref="username" 
                              outlined 
                              dense
                              v-model="form.register.data.username" 
                              :rules="form.register.rules.username" 
                              label="Usuário" 
                              required 
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field 
                                ref="password" 
                                outlined 
                                v-model="form.register.data.password" 
                                :rules="form.register.rules.password" 
                                label="Senha" 
                                required 
                                dense
                                :disabled="loading" 
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                                @click:append="show = !show" 
                                :type="show ? 'text' : 'password'"> 
                              </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" class="text-left">
                            <p class="headline2">Aceite de termos e condições</p>
                              <a href="https://minio-cpe.sebrae.com.br/documento/politica-privacidade.pdf" target="_blank" class="mb-2 v-btn v-btn--text" style="text-transform: none; color: #1976D2; text-decoration: underline;">
                                Termos e Condições
                              </a>
                            <v-switch
                              v-model="acceptedTerms"
                              :label="'Li e concordo com os termos e condições'"
                              color="green"
                              hide-details
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-btn 
                            block 
                            text 
                            large 
                            class="primary-button"
                            :disabled="!form.register.valid || !isPreferenceSelected || !acceptedTerms || loading" 
                            :loading="form.register.loading" 
                            @click="register()">Criar</v-btn>
                          </v-col>               
                        </v-row>
                      </v-form>
                  </v-tab-item>

                  <!-- Modal para termos de uso (pra quando for um texto dentro da aplicação)-->
                  <!-- <v-dialog v-model="termsDialog" max-width="600px">
                    <v-card>
                      <v-card-title class="headline">Termos e Condições</v-card-title>
                      <v-card-text>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="termsDialog = false">Fechar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->

                  <!-- ENVIAR TOKEN DE RECUPERAÇÃO DE SENHA PARA EMAIL CADASTRADO -->
                  <v-tab-item eager>
                    <v-row align="center" justify="center" style="margin-top:0px">
                      <v-form lazy-validation style="padding:20px;">
                        <v-toolbar  elevation="0">
                          <v-btn icon @click="tab=0">
                            <v-icon>mdi-arrow-left</v-icon>
                          </v-btn>
                          <span class="text-body-1 neutral-color-4">Voltar</span>
                        </v-toolbar>  

                        <v-divider></v-divider>
                        <p class="headline2 text-center primary-color" style="padding-top: 16px;">Redefinir Senha</p>
                        <p class="headline3 text-left neutral-color" 
                           style="padding-top: 8px;"
                           >Digite o email cadastrado para enviar o TOKEN de redefinição da sua senha. 
                        </p>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field
                              class="user-field" 
                              hide-details 
                              ref="username" 
                              outlined 
                              v-model="form.login.data.username" 
                              :rules="form.login.rules.username" 
                              label="Usuário" 
                              required 
                              :append-icon="'mdi-account'" 
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-btn :disabled="loading" block large class="primary-button" @click="requestPasswordReset">Enviar Token</v-btn>
                          </v-col>
                          <v-alert type="error" v-model="showError">
                              {{ errorMessage }}
                          </v-alert>                    
                      </v-form>
                    </v-row>
                  </v-tab-item>

                  <!-- REDEFINIÇÃO DE SENHA A PARTIR DE TOKEN RECEBIDO -->
                  <v-tab-item v-if="showTokenTab" eager lazy-validation style="padding:20px;">
                    <v-row align="center" justify="center">
                      <v-form v-if="!resetSuccess">
                        <v-toolbar  elevation="0">
                          <v-btn icon @click="tab=0">
                            <v-icon>mdi-arrow-left</v-icon>
                          </v-btn>
                          <span class="text-body-1 neutral-color-4">Voltar</span>
                        </v-toolbar>  

                        <v-divider style="padding-bottom: 20px;"></v-divider>

                        <v-alert type="success">
                          Token enviado para o email!
                        </v-alert>
                        
                        <p class="headline2 text-center primary-color" style="padding-top: 16px;">Redefinir Senha</p>
                        <p class="headline3 text-left neutral-color" 
                           style="padding-top: 8px;"
                           >Preencha com o TOKEN enviado para seu email. 
                        </p>
                        
                        <!-- Campo de Texto para o Token -->
                        <v-col cols="12">
                          <v-text-field
                            label="Token de 8 Dígitos"
                            v-model="token"
                            :rules="form.login.rules.token"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>

                        <!-- Campo de Nova Senha -->
                        <v-col cols="12"> 
                          <v-text-field 
                            label="Nova Senha"
                            v-model="newPassword"
                            :rules="form.login.rules.newPassword"
                            type="password"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                                    
                        <!-- Botão de Salvar Nova Senha -->
                        <v-col cols="12">
                          <v-btn
                            block
                            large
                            class="primary-button"
                            @click="saveNewPassword"
                          >Salvar Nova Senha</v-btn>
                        </v-col>

                        <v-col cols="12">
                          <v-alert
                            type="error"
                            v-model="showError"
                            dismissible
                          >
                            {{ errorMessage }}
                          </v-alert>
                        </v-col>
                      </v-form>

                      <div v-if="resetSuccess">
                        <v-col lazy-validation style="padding:20px;">
                          <v-alert type="success">
                            Senha redefinida com sucesso.
                          </v-alert>
                          <v-btn
                            block
                            large
                            class="primary-button"
                            @click="goToLogin"
                          >Voltar para Login</v-btn>
                        </v-col>
                      </div>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>                        
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import * as jwt from "jwt-decode";
import VueLodash from "vue-lodash";
import _ from 'lodash';

export default {
  data: () => ({
    username: '',
    password: '',
    newPassword: '',
    token: '',
    loading: false,
    valid: true,
    show: false,
    tab: 0,
    isPreferenceSelected: false,
    showError: false,
    errorMessage: '',
    showTokenTab: false,
    showNewPasswordField: false,
    resetSuccess: false,
    acceptedTerms: false,
    form: {
      login: {
        valid: false,
        loading: false,
        data: {
          username: null,
          password: null
        },
        rules: {
          username: [
            v => !!v || 'Obrigatório',
            v => (v && v.length <= 25) || 'Inválido',
          ],
          password: [
            v => !!v || 'Obrigatorio'
          ],
          newPassword: [
            v => !!v || 'Obrigatório',
            v => (v && v.length >= 8) || 'A senha deve conter no mínimo 8 caracteres',
            v => /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula',
            v => /[a-z]/.test(v) || 'A senha deve conter pelo menos uma letra minúscula',
            v => /[0-9]/.test(v) || 'A senha deve conter pelo menos um número',
          ],
          token:[
            v => (v && v.length === 8) || 'O token deve ter 8 dígitos'
          ],
        }    
      },
      register: {
        valid: false,
        loading: false,
        data: {
          name: null,
          phone: null,
          email: null,
          preference: null,
          username: null,
          password: null
        },
        types: {
            loading: false,
            data: [{text: 'Telefone', value: 1}, {text: 'E-mail', value: 2}]
        },
        rules: {
          name: [
            v => !!v || 'Obrigatório'
          ],
          phone: [
            v => !!v || 'Obrigatorio',
            v => (v && v.replace(/\D/g, '').length >= 10) || 'Telefone inválido. Ex: (##) # ####-####',
          ],
          email: [
            v => !!v || 'Obrigatorio',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Inválido',
          ],
          preference: [
            v => !!v || 'Obrigatorio'
          ],
          username: [
            v => !!v || 'Obrigatorio',
            v => (v && v.length <= 25) || 'Inválido',
          ],
          password: [
            v => !!v || 'Obrigatório',
            v => (v && v.length >= 8) || 'A senha deve conter no mínimo 8 caracteres',
            v => /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula',
            v => /[a-z]/.test(v) || 'A senha deve conter pelo menos uma letra minúscula',
            v => /[0-9]/.test(v) || 'A senha deve conter pelo menos um número',
          ],
          acceptedTerms: [ 
            v => v || 'Você deve aceitar os termos e condições para se registrar.' 
          ],
        }  
      }
    },
    
  }),
    computed: {
    logoClass() {
      return this.tab === 1 ? 'logo-posicao-registro' : 'logo-posicao-normal';
    },
  },
  watch: {
    'tab': {
      handler: function(){
        this.$refs['form.register'].clear();
      }
    },
    'form.register.data.email': {
      handler: _.debounce(function(act, prev) {
        console.log('VEJA:', act)
        this.$nextTick(()=> this.form.register.data.username = act)
        // this.form.register.data.username = this.form.register.data.email;
      }, 200),
    },
    'form.register.data.preference': function (newValue) {
      this.isPreferenceSelected = !!newValue;
    },   
  },

  mounted: function () {
    localStorage.removeItem("token");
    this.$nextTick(function () {
      
      //axios.r
      //this.$refs.form.validate();
    })
  },

  methods: {
    empty(){
      return !this.$data.username || !this.$data.password; 
    },
    login() {
      this.$data.loading = true;
      axios
        .post('/sessions', { username: this.$data.form.login.data.username, password: this.$data.form.login.data.password })
        .then(e => {
          localStorage.setItem('token', JSON.stringify(e.data.data));

          var data = jwt(e.data.data.access);
          
          this.$root.user = data;
          localStorage.setItem('user', JSON.stringify(data));

          router.push("/search");
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat:true, timeout: 5000 });
          this.$data.loading = false;
        });

    },
    register() {
      if (!this.acceptedTerms) {
        this.$dialog.notify.error("Você deve aceitar os termos e condições para se registrar.", { position: 'top-right', outlined: true, flat:true, timeout: 5000 });
        return; 
      }
      this.$data.loading = true;
      axios
        .patch('/users', 
        { operation: 'register', 
        name: this.form.register.data.name, 
        email: this.form.register.data.email, 
        phone: this.form.register.data.phone, 
        preference: this.form.register.data.preference, 
        username: this.form.register.data.username, 
        password: this.form.register.data.password,
        acceptedTerms: this.acceptedTerms,
        })
        .then(e => {
          localStorage.setItem('token', JSON.stringify(e.data.data));

          var data = jwt(e.data.data.access);
          
          this.$root.user = data;
          localStorage.setItem('user', JSON.stringify(data));

          router.push("/search"); 
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat:true, timeout: 5000 });
          this.$data.loading = false;
        });

    },
    validate () {
      if (this.$refs.form.validate()) {
        ;
      }
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    requestPasswordReset() {
      this.loading = true;
      axios
        .post('/users/request-reset', { email: this.form.login.data.username })
        .then(response => {
          if (response.data && response.data.success) {
            console.log("Email validado e token enviado.");
            this.showTokenTab = true; 
            this.tab = 3;
          } else {
            this.showError = true;
            this.errorMessage = "Usuário não encontrado.";
          }
        })
        .catch(error => {
          this.showError = true;
          this.errorMessage = error.response && error.response.data && error.response.data.response
                              ? error.response.data.response
                              : "Ocorreu um erro desconhecido.";
          console.error("Erro ao tentar validar o email:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToLogin() {
      this.tab = 0;
    },
    saveNewPassword() {
      if (this.token.length !== 8 || this.newPassword.length < 8) {
        console.log("Dados inválidos.");
        return;
      }
      axios
        .post('/users/reset-password', {
          token: this.token,
          newPassword: this.newPassword
        })
        .then(response => {
          console.log("Senha alterada com sucesso.");
          this.resetSuccess = true;
        })
        .catch(error => {
          this.showError= true;
          this.errorMessage = "Token ou senha inválidos.";
        });
    },
  }
};
</script>

<style lang="scss">

  // Color variables
  .primary-color { color: #3B4AFF}
  .base-white {color: #FFFFFF}
  .base-black {color: #272829}
  .neutral-color { color: #4E4E4E}
  .neutral-color-4 { color: #858585}
  .gradient { background: linear-gradient(#0686FB, #9800CA);}

  .headline2  {
    font-size: 24px;
    font-family: 'Alegreya Sans', sans-serif; 
    font-weight: 700;
  }

  .body2{
    font-size: 16px;
    font-family: 'Alegreya Sans', sans-serif; 
    font-weight: 400;
  }
 
  .primary-button{
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    background-color: #3B4AFF !important;
    color:#FFFFFF !important;
    border-radius: 8px; 
    height:56px; 
    margin-top:10px
  }

  .primary-button.primary-button.v-btn--disabled, 
  .primary-button[disabled] {
    background-color: #ec5353 !important;
    color: #FFFFFF !important; 
    opacity: 0.5 !important; 
  }


  .sec-button {
    background-color: #FFFFFF;
    border: 2px solid #3B4AFF;
    border-radius: 8px;
    height: 56px;
    margin-top: 10px;
  }

  .sec-button .v-btn__content {
    color: #3B4AFF; 
  }

  .link-like-button {
    color: #1976D2;   
    text-decoration: underline; 
    font-size: 1em; 
  }

  .login-background {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    min-height: 100vh;
    background: url('~@/assets/login-background.svg');
    background-size: cover;
    overflow: auto;
  }
  
  .user-field .v-input__slot fieldset {
    border: 2px solid transparent;
    border-radius: 8px;
    border-color: #3B4AFF;
  }

  .user-field .v-icon {
    background: linear-gradient(#0686FB, #9800CA);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .password-field .v-input__slot fieldset {
    border: 2px solid transparent;
    border-radius: 8px;
    border-color: #272829;
  }

  .password-field .v-icon {
    background: #272829;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .logo-container {
    text-align: center;  
  }

  .logo-posicao-normal {
    width: 300px;
    text-align: center;  
    margin-top: 18vh;
  }

  .logo-posicao-registro {
    width: 300px;
    text-align: center;  
    margin-top: 5vh;
  }

  html, body {
  height: 100% !important;
  margin: 0 !important;
}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }
</style>