<template>
    <v-app class="app">
        <v-navigation-drawer 
                            class="custom-drawer"
                            v-model="drawer"
                             v-if="!hide"
                             app
                             left
                             :mini-variant="!$vuetify.breakpoint.xsOnly"
                             :permanent="!$vuetify.breakpoint.xsOnly"
                             :expand-on-hover="!$vuetify.breakpoint.xsOnly">                             
            <template v-slot:prepend>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar style="height: 30px;min-width: 30px;width: 30px;">
                            <img v-if="$root.user.picture" v-auth-image="`/resources/${$root.user.picture}`" />
                            <img v-if="!$root.user.picture" src="./assets/user.png">
                        </v-list-item-avatar>
                    </v-list-item>

                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="title purple--text">{{ $root.user.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $root.user.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
                    <v-list-item-icon>
                        <v-icon color="purple">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item link to="/login">
                        <v-list-item-icon>
                            <v-icon>power_settings_new</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Sair</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <v-content>
            <v-app-bar class="header custom-app-bar"
                       app
                       v-if="!hide"
                       transition="slide-y-transition"
                       elevate-on-scroll
                       scroll-target="#scroll">
                <v-app-bar-nav-icon @click="open" class="d-flex d-sm-none app-bar-icon"></v-app-bar-nav-icon>
                
                <v-spacer></v-spacer>
        
                <img src="@/assets/logoSebrae.svg" class="app-bar-logo" alt="Logo Sebrae">

                <v-btn icon @click="goHome" style="color: white;">
                    <v-icon size="32">mdi-home</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.suggest.visible" @click.native="$root.actions.suggest.event.next($event)"  :disabled="$root.actions.suggest.disable" :loading="$root.actions.suggest.loading">
                    <v-icon>mdi-comment-question-outline</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.download.visible" @click.native="$root.actions.download.event.next($event)"  :disabled="$root.actions.download.disable" :loading="$root.actions.download.loading">
                    <v-icon>mdi-cloud-download</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.remove.visible" @click.native="$root.actions.remove.event.next($event)"  :disabled="$root.actions.remove.disable" :loading="$root.actions.remove.loading">
                    <v-icon color="error">mdi-delete</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.publish.visible" @click.native="$root.actions.publish.event.next($event)" :disabled="$root.actions.publish.disable"  :loading="$root.actions.publish.loading" >
                    <v-icon color="error">mdi-publish</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.history.visible" @click.native="$root.actions.history.event.next($event)" :disabled="$root.actions.history.disable"  :loading="$root.actions.history.loading" >
                    <v-icon>mdi-history</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.import.visible" @click.native="$root.actions.import.event.next($event)" :disabled="$root.actions.import.disable"  :loading="$root.actions.import.loading" >
                    <v-icon>mdi-import</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.upload.visible" @click.native="$root.actions.upload.event.next($event)" :disabled="$root.actions.upload.disable"  :loading="$root.actions.upload.loading" >
                    <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.address.visible" @click.native="$root.actions.address.event.next($event)" :disabled="$root.actions.address.disable"  :loading="$root.actions.address.loading" >
                    <v-icon>mdi-sign-direction</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.sync.visible" @click.native="$root.actions.sync.event.next($event)" :disabled="$root.actions.sync.disable"  :loading="$root.actions.sync.loading" >
                    <v-icon>mdi-cloud-sync</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.save.visible" @click.native="$root.actions.save.event.next($event)" :disabled="$root.actions.save.disable"  :loading="$root.actions.save.loading" >
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.productivity.visible" @click.native="$root.actions.productivity.event.next($event)"  :disabled="$root.actions.productivity.disable" :loading="$root.actions.productivity.loading">
                    <v-icon>mdi-calendar-multiple</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.create.visible" @click.native="$root.actions.create.event.next($event)"  :disabled="$root.actions.create.disable" :loading="$root.actions.create.loading" style="color:white">
                    <v-icon size="28">mdi-clipboard-plus</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.editnew.visible" @click.native="$root.actions.editnew.event.next($event)"  :disabled="$root.actions.editnew.disable" :loading="$root.actions.editnew.loading">
                    <v-icon>mdi-pencil-lock</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.edit.visible" @click.native="$root.actions.edit.event.next($event)"  :disabled="$root.actions.edit.disable" :loading="$root.actions.edit.loading">
                    <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>

                 <v-btn icon v-if="$root.actions.group.visible" @click.native="$root.actions.group.event.next($event)"  :disabled="$root.actions.group.disable" :loading="$root.actions.group.loading">
                    <v-icon>mdi-group</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.add.visible" @click.native="$root.actions.add.event.next($event)"  :disabled="$root.actions.add.disable" :loading="$root.actions.add.loading">
                    <v-icon>mdi-folder-upload</v-icon>
                </v-btn>
                
                <v-btn icon v-if="$root.actions.filter.visible" @click.native="$root.actions.filter.event.next($event)"  :disabled="$root.actions.filter.disable" :loading="$root.actions.filter.loading">
                    <v-icon>mdi-filter</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.store.visible" @click.native="$root.actions.store.event.next($event)"  :disabled="$root.actions.store.disable" :loading="$root.actions.store.loading">
                    <v-icon>mdi-inbox-arrow-down</v-icon>
                </v-btn>

                <v-btn icon v-if="$root.actions.file.visible" @click.native="$root.actions.file.event.next($event)"  :disabled="$root.actions.file.disable" :loading="$root.actions.file.loading">
                    <v-icon>mdi-file</v-icon>
                </v-btn>
                
            </v-app-bar>

            <v-sheet id="scroll" :class="{ 'login-layout': hide }" class="content">
                <router-view />
            </v-sheet>
        </v-content>
    </v-app>
</template>

<script>
import Vue from "vue";

export default {
    name: "App",

    data: () => ({
        drawer: false,
        authToken: null, 
        options: [
            { title: "Atendimentos", to: "/atendimentos", icon: "mdi-calendar-clock", path: '/atendimentos', methods: ['get'] },
            { title: "Usuários", to: "/users", icon: "mdi-account-multiple", path: '/users', methods: ['get','patch','delete'] },
            { title: "Categorias", to: "/categories", icon: "mdi-bookmark-outline", path: '/categories', methods: ['get','post','delete'] },
            { title: "Documentos", to: "/documents", icon: "mdi-file-chart", path: '/documents', methods: ['get','post','delete'] },
            { title: "Busca", to: "/search", icon: "mdi-file-search-outline", path: '/documents', methods: ['get', 'post'] },
        ],
        items: [],
    }),

    methods: {
        open() {
            this.drawer = !this.drawer;
        },
        goHome() {
            if (this.$route.path === '/search') {
                this.$router.go();
            } else {
                this.$router.push('/search');
            }
        },
        updateAuthToken(token) {
            this.authToken = token;
        },
        calculateItems() {
            const hasPermissionForUsersOrCategories = this.options.some(it => {
                const check = (it.path === '/users' || it.path === '/categories') && this.$check(it.path, it.methods);
                return check;
            });

            this.items = this.options.filter(it => {
                const hasBasicPermission = this.$check(it.path, it.methods);
                const shouldIncludeAtendimentos = it.title === 'Atendimentos' ? hasPermissionForUsersOrCategories : true;
                return hasBasicPermission && shouldIncludeAtendimentos;
            });
        },
        onLogin(token) {
            this.updateAuthToken(token);
        }
    },

    computed: {
        hide() {  
            return this.$route.path === "/login" || this.$route.path === "/register" || /^\/requests\/.*\/view$/.test(this.$route.path);
        }
    },

    watch: {
        hide() {
            this.calculateItems();
        },
        authToken() {
            this.calculateItems();
        }
    },

    mounted() {
        this.calculateItems();
    }
};
</script>

<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .app{
        font-family: 'Alegreya Sans', sans-serif !important;
        font-style: normal;
    }
    
    .custom-drawer {
      background: linear-gradient(135deg, #9900CA 0%, #0586FB 100%);
    }

    .custom-drawer .v-list-item__icon .v-icon,
    .custom-drawer .v-list-item__content .v-list-item__title, 
    .custom-drawer .v-list-item__content .v-list-item__subtitle{
        color: #FFFFFF !important; 
    }

    .theme--light.v-navigation-drawer .v-divider {
    border-color: #FFFFFF !important;
    }

    .custom-drawer .v-list-item__content .v-list-item__title{
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
    }

    .custom-drawer .v-list-item--active {
        background-color: #FFFFFF; 
    }

    .custom-drawer .v-list-item--active .v-list-item__icon .v-icon,
    .custom-drawer .v-list-item--active .v-list-item__content .v-list-item__title {
        background: linear-gradient(#0686FB, #9800CA);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent !important;
    }   
    
    .custom-app-bar {
        background-image: linear-gradient(to right, #9900CA, #0586FB); 
    }

    .app-bar-icon .v-icon {
        color: white !important;
    }

    .app-bar-logo {
        max-height: 100%; 
        max-width: 120px; 
        object-fit: contain;
        margin-left: auto;
    }

    .title {
        font-family: 'Alegreya Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        color: rgba(0,0,0,.6);
        padding: 48px 16px 0px 0px;
    }

    .subtitle {
        font-family: 'Alegreya Sans', sans-serif;
        font-weight: 400;        
        text-align: flex-start;
        color: rgba(0,0,0,.6);        
        padding: 0px 16px 48px 0px;
    }

    .reference {
        font-family: 'Alegreya Sans', sans-serif;
        font-weight: 400;        
        text-align: end;
        font-style: italic;
        color: rgba(0,0,0,.6);        
        padding: 0px 0px 4px 0px;
    }

    .alink {
        color: #0066FF !important;
        text-decoration: underline;
    }

    button:disabled { opacity: 50% !important; }
        
    .content {
        height: calc(100vh - 70px); 
        overflow-y: auto;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')}
    {
        .content{
            height: calc(100vh - 70px); 
        }
    }

    .login-layout {
        height: 100vh !important; 
    }

    .tooltip {
        display: block !important;
        font-family: 'Alegreya Sans', sans-serif;
        font-size: 12px;
        z-index: 10000;
    }
    .tooltip .tooltip-inner {
        background: rgb(94, 91, 91);
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }
    .tooltip .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: rgb(94, 91, 91);
        z-index: 1;
    }
    .tooltip[x-placement^="top"] {
        margin-bottom: 5px;
    }
    .tooltip[x-placement^="top"] .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        color: rgb(94, 91, 91);
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }
    .tooltip[x-placement^="bottom"] {
        margin-top: 5px;
    }
    .tooltip[x-placement^="bottom"] .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        color: rgb(94, 91, 91);
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }
    .tooltip[x-placement^="right"] {
        margin-left: 5px;
    }
    .tooltip[x-placement^="right"] .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        color: rgb(94, 91, 91);
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }
    .tooltip[x-placement^="left"] {
        margin-right: 5px;
    }
    .tooltip[x-placement^="left"] .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        color: rgb(94, 91, 91);
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }
    .tooltip.popover .popover-inner {
        background: #f9f9f9;
        color: rgb(94, 91, 91);
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(rgb(94, 91, 91), .1);
    }
    .tooltip.popover .popover-arrow {
        border-color: rgb(94, 91, 91);
        ;
    }
    .tooltip[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }
    .tooltip[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
</style>